import React from "react";

const Vision = () => {
  return (
    <div>
      <h2>Vision</h2>
      <ul>
        <li>
          Summer 2024: I started a software startup and hired a SWE intern. I am
          so insanely proud of myself for building this MVP in just 1 month
          fulltime.
        </li>
        <li>
          1L: Allow myself to enjoy the graduate school experience. Build my
          network. If my startup takes off then I am so lucky!
        </li>
        <li>1L Summer: Attend YC cohort.</li>
        <li>2028: Forbes 30 under 30 in Enterprise Software.</li>
        <li>2030: IPO my company.</li>
        <li>2045: Start my philanthropy foundation.</li>
      </ul>

      <br />
      <h2>Ideas Board</h2>
      <ul>
        <li>Data is king</li>
        <li>Adoption is disruptive</li>
        <li>It's all about the long-term</li>
        <li>Nirvana</li>
      </ul>

      <br />
      <h2>Favorite Quotes</h2>
      <ul>
        <li>
          “If I had an hour to solve a problem, I’d spend 55 minutes thinking
          about the problem and 5 minutes thinking about solutions.” — Albert
          Einstein
        </li>
      </ul>

      <h2>Self-Awareness</h2>
      <ul>
        <li>
          My superpower is that I learn very quickly. I have breadth of
          knowledge.
        </li>
        <li>I am kind, adaptable, and relentless.</li>
        <li>
          Sometimes I push others too far because I like to push myself. I can
          come off as intense to some.
        </li>
      </ul>
    </div>
  );
};

export default Vision;
