import React, { useEffect, useState } from "react";

const SplashScreen = () => {
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFade(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`splash-screen ${fade ? "visible" : "fading"}`}>
      <h1>Welcome...</h1>
    </div>
  );
};

export default SplashScreen;
