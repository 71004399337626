import React from "react";

const PersonalEssay = () => {
  return (
    <div>
      <p>
        Preface: I wrote this personal essay at age 21 for Harvard Law School's
        2 year deferred program. Life did not turn out as planned. I thought I
        would be an investment banker for 2 years post graduation. I ended up
        working at a self-driving car startup for 3 months before being impacted
        by the first round of a mass layoff. Then, I moved my life to New York
        City to work at a tech company for 15 months before quitting to start my
        own software company. My takeaway as I reflect on my past writing is
        that life is unpredictable and does not go as planned. However, a
        definite plan is better than no plan at all.
      </p>
      <p>June 30, 2021</p>
      <p>
        My long-term goal is to one day be appointed the Canadian Minister of
        Finance. To give more context to this ambitious aspiration, let me
        explain my backstory.
      </p>
      <p>
        I’m a Chinese-born, Canadian-raised immigrant attending college in the
        United States. By selecting Vanderbilt University over Canadian
        universities, I chose to start a completely new chapter of my life in
        America. This decision, to use a hackneyed saying, truly changed the
        trajectory of my life. Experiencing life in America has broadened my
        worldview and galvanized my end goal. In just under three years, I’ve
        learned why the world views America as the “land of opportunity”, a
        country of prosperity and novel ideas. Growing up in Canada, I’ve
        experienced and observed the complete opposite: meager career
        opportunities for youth, lack of entrepreneurship incentives due to a
        50% capital gains tax, and sluggish technological innovation. The most
        recent and alarming manifestation of the weak Canadian economy is my
        country’s inability to develop its own vaccine due to insufficient
        investment in pharmaceutical research and infrastructure required for
        independent vaccine production. I became fully vaccinated long before my
        mother, an at-risk high school math teacher in Toronto who had to wait
        three months for her second dose of the COVID-19 vaccine. To me,
        Canada’s ill-equipped response to the COVID-19 pandemic is a product of
        inadequate economic policies that stunt GDP growth and restrict
        innovation, ineffective systems that have plagued my country for far too
        long. Driven by my frustration, my goal is to revitalize the Canadian
        economy, a goal I will prioritize as Canada’s Minister of Finance.{" "}
      </p>{" "}
      <p>
        How would a law degree help me achieve this assertive goal of
        transforming the inner workings of my home country’s economy? The
        context of my academic background and career aspirations can help answer
        this question. I have spent the last three years as a computer science
        major honing my quantitative skills, laboring through linear algebra
        problem sets and coding programs to solve a Sudoku puzzle. A law degree
        will complement my technical skill set by equipping me with the
        self-advocacy skills requisite for someone who hopes to hold a public
        office position: how to present and represent oneself in legal and
        legislative settings. If I am to be proposing effective economic
        policies, I need to be articulate on paper and through speech. A law
        education will substantially improve my qualitative skills, making a
        J.D. degree the perfect complement to my highly quantitative
        undergraduate degree. The JDP program, in particular, is a perfect fit
        for me because it will allow me to continue my current career in
        investment banking prior to matriculating into Harvard Law School. In
        these two gap years working in finance, I will fervently learn all that
        I can about the American capital markets, lessons which I plan on
        adapting to benefit the Canadian capital markets. Equipped with my
        investment banking experience, I hope to then scrupulously study the
        legal and regulatory environment of the powerhouse American economy in
        classes such as Constitutional Law: Money and the Making of American
        Capitalism and Antitrust Law & Economics. Moreover, Harvard’s alumni
        network is much stronger than comparable schools due to the large law
        class size, making me confident I will be able to find mentors with
        similar career aspirations. The aforementioned factors make me firmly
        convinced that a Harvard Law education will provide me the best toolkit
        and network to tackle Canadian economic policy.{" "}
      </p>
      <p>
        Evidently, the JDP program is the perfect fit for my particular
        background and career aspirations so I’d like to propose why the
        converse is simultaneously true. I offer to the Harvard Law Class of
        2027 my unique perspectives as a female engineer, international student,
        and investment banker. As a female computer scientist, I know what it
        feels like to be the only woman in a 15-person math class. Feeling like
        a minority has forced me to understand the importance of closing gender
        gaps. We have not yet reached gender equality in the field of law, and I
        want to be a part of the solution. My unconventional pre-law major also
        positions my mind to define and solve problems through a highly
        systematic framework. Secondly, as a Canadian citizen who immigrated
        from China, I will bring the cultural perspectives of two entirely
        different countries and my experiences overcoming the obstacles
        presented solely to international students, from the stress of not
        having healthcare coverage in a foreign country to the fear of being
        denied a work visa. My experience as an intersectional minority will
        allow me to empathize with marginalized communities when creating
        economic policies that impact real people. Lastly, my two years of work
        experience will make me a valuable member in classroom discussions, with
        my expertise in the capital markets contributing to the exchange of
        diversified knowledge among my classmates.
      </p>
      <p>
        In summary, I offer to the JDP program my multifaceted aspirations and
        identities in hopes that Harvard will see the fit as perfect as I do.
      </p>
    </div>
  );
};

export default PersonalEssay;
