import React from "react";

const Overwhelmed = () => {
  return (
    <div>
      <p>September 8, 2024 </p>
      <h2>Life Lately</h2>

      <p>
        This is an organized brain dump of how my life has been going since
        moving to Cambridge two weeks ago.
      </p>

      <h2>Week 1 of law school, check</h2>
      <p>
        Overwhelmed. That is the only word that can begin to describe how I
        feel. So many new people from sectionmates to professors to fellow
        Harvard students. So many brilliant people, such a concentration of
        intellect, and a density of overachievement. I have a good reason to
        believe the future President of the United States is in my section.
        Moreover, it is not lost upon me the extreme privilege to be taught by
        professors who wrote the very textbooks used by law students across the
        nation. The same professors who care so much about their job such as to
        make flashcards to memorize all 80 names and faces from our section
        before the first day of class.
      </p>
      <h2>I know more about LSD than I needed to</h2>
      <p>
        And it's not just overwhelmed by people. I'm overwhelmed by words. We
        are assigned 50-70 pages of reading a day and mostly cases from 100-200
        years ago written in old English. To name a few of the more interesting
        ones, I have read cases about LSD, whale oil, fox hunting, and two men
        fighting over a baseball. The legal field has a distinct and large set
        of jargon that my brain is rapidly absorbing through context clues from
        sitting in the classroom. The legal profession seems to asymmetrically
        attract verbosity. All the legal opinions I'm reading use five sentences
        to flesh out a point that could be made in one. On top of that, all my
        law school classmates are extremely chatty. In my undergraduate
        engineering classes, you could barely get one student to raise their
        hand. I'm definitely in the minority who believes that less is more.
      </p>

      <h2>Overwhelmed by work</h2>
      <p>
        Work is going quite well. I found two amazing co-founders with six years
        of experience in big tech. I enjoy working with them and feel excited
        when we meet and collaborate online from three different cities. My
        co-founders agreed that I'm the natural choice for CEO of Enttor and
        that I am the most technical founder. Having relearned coding in three
        months after a two-year hiatus in Technical Program Management roles, I
        am flattered. Of course, I am aware that in a 4 person company, the
        titles are a little aspirational at this point. However, this step makes
        me optimistic for the future. Throughout my life, various good friends
        have expressed that I would be suitable for such a role, even so
        generous as to say I would one day run a Fortune 500 company. I also
        agree my skills are best suited for such a leadership role. However, I
        have always kept in mind that it's best not to let ego and fancy job
        titles get in the way of running a successful business.
      </p>

      <h2>Simply grateful</h2>

      <p>
        Overwhelmed by support. I have received so many messages of people
        congratulating me, not just for starting at Harvard, but also for
        starting my own business. Even a classmate from high school in Canada
        who I have not interacted with for six years unexpectedly reached out
        with supportive sentiments. A fellow Vanderbilt alum who I vaguely
        recall meeting once in freshman year DMed me on Instagram to express her
        excitement for all I will achieve in the future. Viral videos and posts
        on social media are spiking my dopamine levels in a way I'm not sure is
        healthy. I have received more questions about law school and startups
        than I can feasibly answer given my time constraints. While I hate to
        gatekeep information, I must wait for Mark Zuckerberg to improve the
        Instagram AI Agent so I can train AI Zinnie to answer all the frequently
        asked questions.
      </p>

      <p>
        Overwhelmed by emotions. Fear, ambiguity, excitement, exhaustion.
        Exhaustion is a prominent one because my eye bags have been with me
        every day in the last two weeks. But above all, gratitude. Gratitude
        because 16-year-old Zinnie wrote in a letter to her future self that she
        would one day attend Harvard.
      </p>
    </div>
  );
};

export default Overwhelmed;
