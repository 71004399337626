import React from "react";

const Principles = () => {
  return (
    <div>
      <div>
        <h2>Life Principles</h2>
        <p>
          There are an infinite number of variables in life. Life is an
          n-dimensional equation to be solved. Below are some principles by
          which I default to make sense of the chaos. This is a living breathing
          document that is subject to change as I walk through the journey that
          is life.{" "}
        </p>
        <ol>
          <li>Keep it simple. </li>
          <ul>
            <li>Don't overcomplicate a problem. Don't overcomplicate life.</li>
          </ul>
          <li>Get it done.</li>
          <ul>
            <li>
              Do as you say you will do. Don't bother making excuses to others
              and most importantly, to yourself.{" "}
            </li>
          </ul>
          <li>Work smart. Work hard.</li>
          <ul>
            <li>
              Working hard alone does not get you anywhere meaningful. Disern
              the right direction in which to be working. Equipt yourself with
              the best tools to get the job done.
            </li>
          </ul>

          <li>Good process doesn't excuse bad results.</li>
          <ul>
            <li>
              A non-profit is the good example of this principle. Just because
              you set out with the intent to make some philanthropic impact,
              does not mean you actually did it. Half the time, people don't
              even bother to define the result they want. Quantify the results
              you want.
            </li>
          </ul>

          <li>
            Rule of Three
            <ul>
              <li>3 is my favorite number.</li>
              <li>
                When you feel paralyzed by optionality, only allow yourself to
                pick between 3 options.
              </li>
              <li>
                When making a presentation or telling a story, pick the 3 most
                important points you want to make. People will likely only
                remember one thing anyways so try not to exceed 3.
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div>
        <h2>Software Engineering Principles</h2>
        <p>
          In the realm of software engineering, certain principles guide the
          creation of robust, maintainable, and efficient code. These principles
          serve as a foundation for good software design and development
          practices. Here are a few key principles I adhere to:
        </p>
        <ol>
          <li>
            Keep it simple. Avoid unnecessary complexity and over-engineering.
            Simple code is easier to understand, maintain, and debug.
          </li>
          <li>
            DRY - Don't repeat yourself. Make reusable components. Every piece
            of logic should have a single representation in the system.
          </li>

          <li>
            You aren't going to need it. - Don't add additional functionality
            until it is absolutely necessary.
          </li>
          <li>
            Readability - Write code that is easy to read and understand rather
            than trying to be clever and solve a problem in as few lines as
            possible. Another human will inevitably have to read your code,
            debug, and maintain your code so you are just causing more headaches
            for someone else down the line.
          </li>
          <li>
            Keep up - Keep up with the newest framesworks being developed and
            introduced. I use X to stay up to date as there is a robust
            community of developers there. Migrate to new technologies where
            applicable as soon as possible to avoid accruing more tech debt.
          </li>
        </ol>
      </div>
    </div>
  );
};
export default Principles;
