import Thumbnail1 from "../assets/workbox.png";
import Thumbnail2 from "../assets/sentiment.png";

const Portfolio = () => (
  <div>
    <h2>An autonomous vehicle algorithm designed using Julia.</h2>
    <li>
      <a href="https://github.com/zinniezhang/autonomousvehiclesimulator/blob/main/README.md">
        GitHub Link
      </a>
    </li>
    <br />
    <img
      className="portfolio-thumbnail"
      src="https://github.com/VAMPIR-Lab/Interstate.jl/raw/main/racing-vid.gif"
      alt="Racing Video"
    />
    <h2>
      A machine learning model built to classify the sentiment of songs,
      leveraging the Spotify API.
    </h2>
    <li>
      <a href="https://github.com/zinniezhang/ml-project">GitHub Link</a>
    </li>
    <br />
    <img className="portfolio-thumbnail" src={Thumbnail2} alt="Workbox Demo" />
    <h2>
      A Progressive Web Application showing the most popular movies, optimized
      with Workbox caching.
    </h2>
    <li>
      <a href="https://github.com/zinniezhang/react-workbox-demo">
        GitHub Link
      </a>
    </li>
    <br />
    <img className="portfolio-thumbnail" src={Thumbnail1} alt="Workbox Demo" />
  </div>
);

export default Portfolio;
