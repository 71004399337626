import React from "react";

const MyMother = () => {
  return (
    <div>
      <p>September 24, 2024</p>

      <p>
        Instead of doing my Legislation and Regulation reading, I have decided
        to unpack my childhood trauma. To give some context, one of my
        classmates asked me if I was going home to Toronto for Christmas break.
        Awkwardly, I answered no. What I did not explicity say was that the
        house I grew up in is not my home and I make an active effort not to go
        there. This is very personal, emotionally loaded essay, but this is my
        truth. Reader, you have been sufficiently warned.
      </p>

      <h2>Who is my mother</h2>

      <h2>My mother is a a great mother</h2>
      <h2>My mother is mentally ill</h2>
      <p></p>

      <h2></h2>
      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <h2></h2>
      <p></p>

      <ul>
        <li></li>
        <li></li>
      </ul>

      <p></p>
    </div>
  );
};

export default MyMother;
