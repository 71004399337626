import React from "react";

const EldestDaughter = () => {
  return (
    <div>
      <p>August 20, 2024</p>
      <h2>Unlearning How to Be an Eldest Daughter</h2>

      <p>
        Being the eldest daughter in a family is more than just a birth order;
        it's a role that often comes with a set of unspoken expectations and
        ingrained behaviors. As I've grown older and from talking to other
        eldest daughters, I've come to recognize the need to unlearn some of
        these deeply rooted patterns as they are harmful to my mental health and
        well-being.
      </p>

      <h2>What is an Eldest Daughter?</h2>
      <p>
        Scientific studies have long examined the effects of birth order on
        personality and behavior. When it comes to eldest daughters, research
        has consistently identified several common tendencies. Three recurring
        traits amongst these studies are: overachieving, perfectionism, and
        caretaking. From an early age, the eldest daughter is frequently
        entrusted with tasks that go beyond her years, such as setting an
        example for younger siblings and managing household responsibilities.
        This sense of duty is often amplified by societal norms that incline
        females to be more nurturing and family-oriented than an eldest son.
      </p>

      <h2>My Experience as an Eldest Daughter</h2>
      <p>
        As an eldest daughter, I embody the quintessential traits of
        overachievement, perfectionism, and caretaking to a degree that’s almost
        comical if it wasn’t so exhausting. I've always been an academic
        overachiever. Of course I go to Harvard. My undergrad GPA as a Computer
        Science major was 3.97/4.00 and my GRE score was in the 97th percentile.
        But it didn’t stop at academics. I’m a life overachiever too. Case in
        point: I'm writing this essay as a form of self-therapy, turning what
        could simply be a 60-minute therapy session into an extensive written
        analysis on a website I coded.
      </p>

      <p>
        Moreover, I am a recovering perfectionist in multiple areas of my life.
        I won’t leave the house without looking polished, my Instagram is
        meticulously curated to fit my aesthetic. Any less-than-flattering
        photos on my camera roll make me uncomfortable and are instantly
        deleted. I must appear put together to the outside world.
      </p>

      <p>
        And then there’s the caretaking. My role in the family has always been
        more than just “daughter.” My mother unintentionally reinforces these
        tendencies, seeking my advice on major parenting decisions. The most
        extreme example is when she asked me to adopt my brother in the case
        that she passed away from cancer. My father doesn't know how to manage
        his finances. How can I even start to explain the concept of the stock
        market to him, much less venture capital or the economic upside of tech
        startups?
      </p>

      <p>
        The pinnacle of this caregiving dynamic can be illustrated by an
        incident when I was just 14, pushing my baby brother in a stroller. A
        well-meaning stranger approached me with information about after-school
        programs, mistaking me for a teenage mother. This example is just one of
        many manifestations of the disconnect between my mental age and actual
        age. The older I've gotten, the gap has only widened. I cannot date men
        close to my age because they seem much too immature and lack life
        experience. I am uninterested in several of the popular activities and
        discussion topics amongst most 24-year-old females.
      </p>

      <h2>Not Just an Eldest Daughter</h2>
      <p>
        Recognizing these patterns is actually a recent discovery for me. Now
        the next step is to make an active effort to unlearn behaviors that have
        been a part of my identity for so long. This is what I am promising
        myself:
      </p>

      <ul>
        <li>
          Letting my family members fail and not feeling guilty about it. It's a
          difficult pill to swallow, especially when it involves people I love.
          Allowing them to fail without intervening can be hard, but life itself
          is the greatest teacher.
        </li>
        <li>
          Asking for help. I'm slowly getting rid of the belief that I should
          shoulder every burden alone. Yes, the outcome may be worse if I don't
          do it myself, but not everything needs to be perfect. This one is
          going to be harder for me because as a software engineer, I'm
          programmed to optimize everything I touch.
        </li>
      </ul>

      <p>
        I don't discount my eldest daughter tendencies which have helped me
        become someone I am proud of today. They've pushed me to achieve many
        things I am genuinely proud of. Moving forward isn’t about abandoning my
        eldest daughter traits,but knowing when to exercise perfectionism and
        when to pull back. I have so many other identities other than an eldest
        daughter that I am excited to nurture. I'll be happy if this personal
        essay can help just even one other eldest daughter, who thinks the
        burden is theirs alone to bear.
      </p>
    </div>
  );
};

export default EldestDaughter;
