const InvestmentThesis = () => (
  <div>
    <h2>My Investment Thesis</h2>
    <p>
      My investment thesis is inspired by thinkers like Morgan Housel, focusing
      on the intersection of good for the world and good for profits. I very
      much believe in the powers of compounding but don't align with investors
      like Warren Buffet. Life is too short to just stockpile cash and not use
      your money to enjoy the fruits of your hard work and intellect. Above all,
      the most important investment you can make is in yourself. I would rather
      invest money into my health, education, relationships, and business than
      someone else's stock.
    </p>
    <p>
      Books, people, and life experiences that have influenced my personal
      investment strategy:
    </p>

    <ul>
      <li>Zero to One by Peter Thiel, Blake Masters</li>
      <li>
        "The most controversial question business is whether success comes from
        luck or skill. A few, like Steve Jobs, Jack Dorsey, and Elon Muck have
        started multiple billion-dollar companies. If success were mostly a
        matter of luck, these kinds of serial entrepreneurs wouldn't exist. But
        if it were entirely a matter of skill, then these kinds of serial
        entrepreneurs would be much more common".
      </li>
      <li>
        "Monopolists can afford to think about things other than making
        money;non-monoloplists can't. In perfect competition, a business is so
        focused on today's margins that it can't possibly plan for a long-term
        future. Only one thing can allow a business to transcend the daily brute
        struggle for survival: monopoly profits."
      </li>
    </ul>
  </div>
);

export default InvestmentThesis;
