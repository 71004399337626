import Image1 from "../assets/risk.png";
import Image2 from "../assets/conviction.png";
import Image3 from "../assets/math.png";
import Image4 from "../assets/compound.png";
import Image5 from "../assets/revenge.png";
import Image6 from "../assets/knowledge.png";
import Image7 from "../assets/definite-optimist.png";

const images = [Image7, Image1, Image2, Image3, Image4, Image6];

const Gallery = () => (
  <div className="parent">
    <div className="gradient-grid">
      <h2>Gallery</h2>
      <p>Below are digital artworks I created.</p>
      <div className="image-grid">
        {images.map((img, idx) => (
          // eslint-disable-next-line
          <img
            key={idx}
            src={img}
            alt={`Gallery Image ${idx + 1}`}
            className="bulge-image"
          />
        ))}
      </div>
    </div>
  </div>
);

export default Gallery;
