import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import "./App.css";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import SplashScreen from "./pages/SplashScreen";
import Vision from "./pages/Vision";
import InvestmentThesis from "./pages/Investor";
import Writing from "./pages/Writing";

//Articles
import EldestDaughter from "./articles/EldestDaughter";
import PersonalEssay from "./articles/PersonalEssay";
import Principles from "./articles/Principles";
import Overwhelmed from "./articles/Overwhelmed";
import SecondImpressions from "./articles/SecondImpressions";
import MyMother from "./articles/MyMother";
import Startup from "./articles/Startup";

import Video1 from "./assets/flame.gif";

// Sidebar component for navigation
const Sidebar = () => (
  <div className="App-sidebar">
    <nav>
      <ul>
        <li>
          <Link to="/home">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/gallery">Gallery</Link>
        </li>

        <li>
          <Link to="/writing">Writing</Link>
        </li>
      </ul>
    </nav>
  </div>
);

// Main App Component
function App() {
  return (
    <Router>
      <SplashScreen />
      <div className="App">
        <header className="App-header">
          <h1>Zinnie Zhang</h1>
          <i>
            <h3>J.D. Candidate, Harvard Law School</h3>
            <h3>B.S. Computer Science, Vanderbilt University</h3>
          </i>
        </header>
        <div className="App-body">
          <img
            className="portfolio-thumbnail"
            src={Video1}
            alt="Background Video"
            style={{
              position: "absolute",
              width: "100vw",
              height: "100vh",
              left: "50%",
              top: "50%",
              objectFit: "cover",
              transform: "translate(-50%, -50%)",
              zIndex: "-1",
            }}
          />

          <Sidebar />
          <main className="App-main">
            <Routes>
              <Route path="/" element={<SplashScreen />} />
              <Route path="/home" element={<Home />} />

              <Route path="/about" element={<About />} />
              <Route path="/gallery" element={<Gallery />}></Route>
              <Route path="/writing" element={<Writing />}></Route>

              {/* Hidden pages */}
              <Route path="/investor" element={<InvestmentThesis />}></Route>
              <Route path="/vision" element={<Vision />}></Route>
              <Route path="/portfolio" element={<Portfolio />} />

              {/* Add new articles here */}
              <Route path="/writing/my-mother" element={<MyMother />} />
              <Route
                path="/writing/second-impressions"
                element={<SecondImpressions />}
              />
              <Route path="/writing/overwhelmed" element={<Overwhelmed />} />

              <Route
                path="/writing/eldest-daughter"
                element={<EldestDaughter />}
              />
              <Route path="/writing/startup" element={<Startup />} />
              <Route
                path="/writing/personal-essay"
                element={<PersonalEssay />}
              />
              <Route path="/writing/principles" element={<Principles />} />
            </Routes>
          </main>
        </div>
        <footer className="App-footer"></footer>
      </div>
    </Router>
  );
}

export default App;
