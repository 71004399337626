import React from "react";

// copilot, please generate an html linked div

const About = () => (
  <div>
    <div>
      <h2>Interests</h2>
      <ul>
        <li>
          Programming
          <ul>
            <li>
              I’m a full-stack developer with a passion for modern website
              design. I am currently building Enttor and am in need of rockstar
              software engineers. Please contact zzhang@ent-tor.com if you are
              interested.
            </li>
          </ul>
        </li>
        <br />
        <li>
          Reading. Some of my favorite books:
          <ul>
            <li>Zero to One by Peter Thiel, Blake Masters</li>
            <li>Laws of Human Nature by Robert Greene</li>
            <li>Outliers by Malcolm Gladwell</li>
            <li>A Promised Land by Barack Obama</li>
            <li>
              {" "}
              Not a book but a great blog post by Sam Altman titled{" "}
              <a href="https://blog.samaltman.com/what-i-wish-someone-had-told-me">
                "What I Wish Someone Had Told Me"
              </a>
            </li>

            <li>
              Side note: I believe all book are fiction because they are written
              by humans who are biased.
            </li>
          </ul>
        </li>
        <br />
        <li>
          Health
          <ul>
            <li>
              Want-to-be vegan who has settled for being a dairy-avoidant
              pescatarian.
            </li>
            <li>
              Although seemingly extreme, I borrow elements of Bryan Johnson's
              diet.
            </li>
            <li>I religiously go to Soulcycle 3-4 times a week.</li>
          </ul>
        </li>
        <br />
        <li>
          Fashion
          <ul>
            <li>
              As an aggressive minimalist (if you couldn't already tell from
              this website), my entire wardrobe easily fits into a single
              checked bag. My philosopy is to invest in quality pieces that are
              timeless.
            </li>
            <li>
              My favorite fashion house is Dior.“High heels? Painful pleasure.”
              - Christian Dior
            </li>
            <li>
              I love a good blazer. It's the piece I reach for when I want to
              feel both polished and powerful.
            </li>
            <li>
              Why does fashion matter? Fashion is more than vanity. It's a tool
              to become the person you want to be. When you dress for the job
              you want, you're subconsciously programming yourself and others to
              see you in that light.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
);

export default About;
